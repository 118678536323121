export var SearchList = [
  { labe: '供应商', code: 'supperName', type: 'input', placeholder: '编码/名称' }

]
export var SearchData = {
  supperName: ''
}
export var menudata = [{
  label: '+新增',
  action: 'CreateaStatement',
  id: 1
}]
